import React from "react";

import { useSelector } from "react-redux";
import { fetchVisitorCartByVisitorToken } from "./loadVisitorFunctions";
import StoreConnectGuestCartEdit from "../../store/storeConnectGuestCartEdit";


function LoadVisitorCart( props ) {

    const { guest_cart, guest_cartFetch, guest_cartMutate } = props;
    const visitor = useSelector( state => state?.visitor );

    // Request guest cart
    React.useEffect( () => {
        if ( !guest_cart?.data?.cart_id &&
            !guest_cart?.cartRequested &&
            visitor?.data?.visitor_token ) {

            guest_cartFetch( fetchVisitorCartByVisitorToken( visitor?.data?.visitor_token ) );
            guest_cartMutate( { cartRequested: true } );

        }
    }, [ guest_cartFetch, guest_cart, visitor, guest_cartMutate ] );
    return null;
}

export default StoreConnectGuestCartEdit( LoadVisitorCart );
