import React from "react";
import DefaultRouter from "../router/DefaultRouter";
import {GlobalStyles} from "@mui/material";
import "./app.css";
import LoadVisitorToken from "../componentsRunOnLoad/visitorToken/LoadVisitorToken";
import LoadVisitorCart from "../componentsRunOnLoad/visitorCart/LoadVisitorCart";
import LoadUserProfile from "../componentsRunOnLoad/UserProfile/LoadUserProfile";
import LoadProductCategories from "../componentsRunOnLoad/generalMatter/LoadProductCategories";


function App() {

    return (
        <div className="App">
            <div className={"overlay"}>
                <GlobalStyles styles={{body: {backgroundColor: "rgb(3,65,56)"}}}/>
                <LoadVisitorToken/>
                <LoadVisitorCart/>
                <LoadUserProfile/>
                <LoadProductCategories/>
                <DefaultRouter/>
            </div>
        </div>
    );
}

export default App;
