import { connect } from "react-redux";
import reduxStoreUtility from "./lib";

const prSct = reduxStoreUtility.createActions( "Guest_Cart" );
const guest_cartMutate = dispatch => keyValue => dispatch( prSct.guest_cartMutate( keyValue ) );
const guest_cartReset = dispatch => resetValue => dispatch( prSct.guest_cartReset( resetValue ) );
const prsAct = reduxStoreUtility.createAsyncActions( "Guest_Cart" );
const guest_cartFetch = dispatch => request => dispatch( prsAct.guest_cartFetch( request ) );


function StoreConnectGuestCartEdit( Element ) {

    const mapStateToProps = state => {
        return {
            guest_cart: state.guest_cart,
        };
    };

    const mapDispatchToProps = dispatch => {
        return {
            guest_cartFetch: guest_cartFetch( dispatch ),
            guest_cartMutate: guest_cartMutate( dispatch ),
            guest_cartReset: guest_cartReset( dispatch )
        };
    };

    return connect( mapStateToProps, mapDispatchToProps )( Element );
}

export default StoreConnectGuestCartEdit;
