import { connect } from "react-redux";
import reduxStoreUtility from "./lib";

const prSct = reduxStoreUtility.createActions( "Categories" );
const categoriesMutate = dispatch => keyValue => dispatch( prSct.categoriesMutate( keyValue ) );
const categoriesReset = dispatch => resetValue => dispatch( prSct.categoriesReset( resetValue ) );
const prsAct = reduxStoreUtility.createAsyncActions( "Categories" );
const categoriesFetch = dispatch => request => dispatch( prsAct.categoriesFetch( request ) );


function StoreConnectCategoriesEdit( Element ) {

    const mapStateToProps = state => {
        return {
            categories: state.categories,
        };
    };

    const mapDispatchToProps = dispatch => {
        return {
            categoriesFetch: categoriesFetch( dispatch ),
            categoriesMutate: categoriesMutate( dispatch ),
            categoriesReset: categoriesReset( dispatch )
        };
    };

    return connect( mapStateToProps, mapDispatchToProps )( Element );
}

export default StoreConnectCategoriesEdit;
