import React from "react";

import { getReqCategoriesList } from "../../sections/header/search/lib/searchLib";
import storeConnectCategoriesEdit from "../../store/storeConnectCategoriesEdit";


function LoadProductCategories( props ) {
    const {
        categoriesReset,
        categoriesFetch
    } = props

    React.useEffect( () => {
        categoriesFetch( getReqCategoriesList() );
        return () => {
            categoriesReset( {} );
        };
    }, [ categoriesReset, categoriesFetch ] );

    return null;
}

export default storeConnectCategoriesEdit( LoadProductCategories );
