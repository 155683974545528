/******************************************************************************
 * Written by Peter Craftsmn                                                  *
 * All rights reserved 2019                                                   *
 ******************************************************************************/

import { combineReducers } from "redux";
import reduxStoreUtility from "./lib";

// Note argument to createReducer is not case sensitive.
const user = reduxStoreUtility.createReducer( "user" );
const userProfile = reduxStoreUtility.createReducer( "User_Profile" );
const item = reduxStoreUtility.createReducer( "ITEM" );
const items = reduxStoreUtility.createReducer( "Items" );
const categories = reduxStoreUtility.createReducer( "CATEGORIES" );
const cart = reduxStoreUtility.createReducer( "Cart" );
const guestCart = reduxStoreUtility.createReducer( "Guest_Cart" );
const visitor = reduxStoreUtility.createReducer( "visitor" );

const app = combineReducers( {
    user: user.userReducer, // Note the prefix 'user' in userReducer
    user_profile: userProfile.user_profileReducer,
    item: item.itemReducer,
    items: items.itemsReducer,
    categories: categories.categoriesReducer,
    cart: cart.cartReducer,
    guest_cart: guestCart.guest_cartReducer,
    visitor: visitor.visitorReducer
} );

export default app;
