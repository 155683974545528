import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import initialStateFromJsonFile from "./initialState.json";
import rootReducer from "./rootReducer";

// Load the locally stored user to the store.
const storedUser = JSON.parse( localStorage.getItem( 'user' ) );
const storedVisitor = JSON.parse( localStorage.getItem( 'visitor' ) );

// Create initial state.
const initialStateForStoreInitialization = {
    ...initialStateFromJsonFile,
    user: storedUser || initialStateFromJsonFile.user,
    user_profile: initialStateFromJsonFile.user_profile,
    cart: initialStateFromJsonFile.cart,
    guest_cart: initialStateFromJsonFile.guest_cart,
    visitor: storedVisitor || initialStateFromJsonFile.visitor
};


// Initialize store.
const store = createStore(
    rootReducer,
    initialStateForStoreInitialization,
    applyMiddleware( thunk )
);

export default store;
