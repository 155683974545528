import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Loading() {
    //Todo:: Test internet connection etc. may be check if internet is connected.
    //Todo:: Show more meaningful result after certain time, that failure has happened

    return (
        <Box sx={ { display: 'flex', justifyContent: "center", alignItems: "center", height: "100vh" } }>
            <CircularProgress size='5rem'/>
        </Box>
    );
}
