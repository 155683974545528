import React, { Suspense } from "react";
import {
    BrowserRouter, Routes, Route
} from "react-router-dom";

import { pathAndURL } from "../config";
import Loading from "../sections/loading/Loading";


// Lazy load pages
const HomePageView = React.lazy( () => import('../views/HomePageView').then( IP => IP ) );
const LoginOrRegisterView = React.lazy( () => import('../views/LoginOrRegisterView').then( IP => IP ) )
const UserLoginView = React.lazy( () => import('../views/UserLoginView').then( IP => IP ) )
const UserCreateAccountView = React.lazy( () => import('../views/UserCreateAccountView').then( IP => IP ) )
const UserRecoverPasswordView = React.lazy( ( () => import('../views/UserRecoverPasswordView').then( IP => IP ) ) );
const UserChangePasswordView = React.lazy( () => import("../views/UserChangePasswordView").then( IP => IP ) );
const UserLogout = React.lazy( () => import("../sections/header/common/Logout").then( IP => IP ) );
const TermsOfUseView = React.lazy( () => import("../views/TermsOfUseView").then( IP => IP ) );
const CareersPageView = React.lazy( () => import("../views/CareersPageView").then( IP => IP ) );
const CareersPage = React.lazy( () => import("../pages/careersPages/CareersPage").then( IP => IP ) );
const JobPostingPage = React.lazy( () => import( "../pages/careersPages/JobPostingPage").then( IP => IP ) );
const NotFoundPageView = React.lazy( () => import('../views/NotFoundPageView').then( IP => IP ) );
const AfterActionView = React.lazy( () => import("../views/AfterActionView").then( IP => IP ) );
const SearchPageView = React.lazy( () => import("../views/SearchPageView").then( IP => IP ) );
const ProfilePage = React.lazy( () => import( "../pages/profilePage/ProfilePage").then( IP => IP ) );
const AddressPage = React.lazy( () => import( "../pages/addressPage/AddressPage").then( IP => IP ) );
const PaymentMethodPage = React.lazy( () => import( "../pages/paymentMethodPage/PaymentMethodPage").then( IP => IP ) );
const OrdersPageView = React.lazy( () => import( "../views/OrdersPageView").then( IP => IP ) );
const ProductPageView = React.lazy( () => import("../views/ProductPageView").then( IP => IP ) );
const ProductDefaultPage = React.lazy( () => import("../pages/productPage/ProductDefaultPage").then( IP => IP ) );
const ProductPage = React.lazy( () => import("../pages/productPage/ProductPage").then( IP => IP ) );
const AccountPageView = React.lazy( () => import("../views/AccountPageView").then( IP => IP ) );
const AccountPage = React.lazy( () => import("../pages/accountPage/AccountPage").then( IP => IP ) );
const CartPageView = React.lazy( () => import("../views/CartPageView").then( IP => IP ) );
const CartPage = React.lazy( () => import("../pages/cartPage/CartPage").then( IP => IP ) );
const CheckoutPage = React.lazy( () => import("../pages/checkoutPage/CheckoutPage").then( IP => IP ) );


function AppRouter() {
    return ( <BrowserRouter>
        <Suspense fallback={ <Loading/> }>
            <Routes>
                <Route exact path={ pathAndURL.rootPath() } element={ <HomePageView/> }/>
                <Route path={ pathAndURL.authUsersLoginOrRegisterPath() } element={ <LoginOrRegisterView/> }/>
                <Route path={ pathAndURL.authUsersLoginPath() } element={ <UserLoginView/> }/>
                <Route path={ pathAndURL.authUsersRegisterPath() } element={ <UserCreateAccountView/> }/>
                <Route path={ pathAndURL.authUsersPasswordRecoverPath() } element={ <UserRecoverPasswordView/> }/>
                <Route path={ pathAndURL.authUsersChangePasswordPath() } element={ <UserChangePasswordView/> }/>
                <Route path={ pathAndURL.authUsersLogoutPath() } element={ <UserLogout/> }/>
                <Route path={ pathAndURL.legalTermsOfUsePath() } element={ <TermsOfUseView/> }/>
                <Route path={ pathAndURL.authEmailConfirmedPath() } element={ <AfterActionView emailConfirmed/> }/>
                <Route path={ pathAndURL.searchStorePath() } element={ <SearchPageView/> }/>
                <Route path={ pathAndURL.ordersPath() } element={ <OrdersPageView/> }/>
                <Route path={ pathAndURL.accountPath() } element={ <AccountPageView/> }>
                    <Route index element={ <AccountPage/> }/>
                    <Route path={ pathAndURL.profilePathNotAbsolute() } element={ <ProfilePage/> }/>
                    <Route path={ pathAndURL.addressPathNotAbsolute() } element={ <AddressPage/> }/>
                    <Route path={ pathAndURL.paymentMethodPathNotAbsolute() } element={ <PaymentMethodPage/> }/>
                </Route>
                <Route path={ pathAndURL.cartPath() } element={ <CartPageView/> }>
                    <Route index element={ <CartPage/> }/>
                    <Route path={ pathAndURL.checkoutPathNotAbsolute() } element={ <CheckoutPage/> }/>
                </Route>
                <Route path={ pathAndURL.authPasswordResetConfirmedPath() }
                       element={ <AfterActionView passwordResetConfirmed/> }/>
                <Route path={ pathAndURL.careersPath() } element={ <CareersPageView/> }>
                    <Route index element={ <CareersPage/> }/>
                    <Route path={ ":id" } element={ <JobPostingPage/> }/>
                </Route>
                <Route path={ pathAndURL.productPath() } element={ <ProductPageView/> }>
                    <Route index element={ <ProductDefaultPage/> }/>
                    <Route path={ ":id" } element={ <ProductPage/> }/>
                </Route>
                <Route path="*" element={ <NotFoundPageView/> }/>
            </Routes>
        </Suspense>
    </BrowserRouter> );
}

export default AppRouter;
