import { createTheme, darken, lighten } from '@mui/material/styles';


import { deepOrange, orange, green } from "@mui/material/colors";

const appTheme = createTheme( {
    palette: {
        primary: {
            light: lighten( "#034138", 0.1 ),
            main: "#034138",
            dark: darken( "#034138", 0.1 ),
            contrastText: '#fff',
        },
        secondary: {
            light: lighten( orange[ 600 ], 0.1 ),
            main: orange[ 600 ],
            dark: darken( orange[ 600 ], 0.1 ),
            contrastText: '#fff',
        },
        emphasis: {
            light: lighten( deepOrange[ 500 ], 0.1 ),
            main: deepOrange[ 500 ],
            dark: darken( deepOrange[ 500 ], 0.1 ),
            contrastText: '#fff',
        },
        cool: { main: green[ 700 ] },
        background: {
            main: "#fffaf6",
            default: "#fffaf6",
        },
        color: {
            main: "#222222",
            grey: "rgba(20,23,20,0.93)",
            greyLight: "rgba(240,240,240)",
        },
        border: {
            color: {
                grey: "rgba(20,23,20,0.48)",
            }
        }
    },
    dimensions: {
        borderRadius: "6px",
        fullWidth: "1200px",
        mediumWidth: "900px",
        smallWidth: "600px",
        topBarHeight: 64,
        categoriesBarHeight: 48,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontWeight: "bold",
                }
            }
        }
    }
} );

export default appTheme;
